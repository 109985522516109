import hexToRgba from 'hex-rgba'
import generateBreakPoints from 'react-jss-grid/utils/breakpoints'

const padding = [0, '80px']
const paddingMobile = [0, '20px']

export const sizes = {
  xs: 575,
  sm: 576,
  md: 720,
  lg: 1024,
  xl: 1085,
  xxl: 1440,
}
const media = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
}
const mediaInverse = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (max-width: ${sizes.md - 1}px)`,
  md: `@media (max-width: ${sizes.lg - 1}px)`,
  lg: `@media (max-width: ${sizes.xl - 1}px)`,
  xl: `@media (max-width: ${sizes.xxl - 1}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
  smLand: `@media (max-width: ${sizes.md - 1}px) and (orientation: landscape)`,
}

function supportPassive() {
  let passiveIfSupported = false
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: () => (passiveIfSupported = { passive: true }),
      }),
    )
  } catch (err) {
    window.console.log('error:: passive event')
  }
  return passiveIfSupported
}

const colors = [
  '#242424', // #0 Dark
  '#ffffff', // #1 White
  '#000000', // #2 Black
  '#f5a94f', // #3 Orange
  '#2c2a32', // #4 Dark Blue -> Background home
  '#acacac', // #5 Grey
  '#e2e2e2', // #6 Medium Grey
  '#f5f5f5', // #7 Light Grey
  '#90bfff', // #8 Celeste
  '#22563f', // #9 Green
  '#3b3070', // #10 Violet
  '#3a3253', // #11 Viola menu
  '#f3f1fa', // #12 Background Scholar
  '', // #10
]

const fonts = [
  '"Albra-grotesk-regular", "Helvetica Neue", Helvetica, Arial, sans-serif', // [0]
  '"Albra-sans-light", serif', // [1]
]

const easings = {
  'power3.out': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // [0]
  'power3.inOut': 'cubic-bezier( 0.645,  0.045,  0.355,  1.000 )', // [1]
  'power4.out': 'cubic-bezier(0.76, 0, 0.24, 1)', // [2]
}

const general = {
  fontFamily: fonts[0],
  fontSize: 16,
  lineHeight: 1.333,
  color: colors[4],
}

const zindex = {
  overlay: 97,
  mainNav: 99,
  header: 100,
  sideRight: 101,
  bottomNav: 102,
  noise: 1000,
  cursor: 102,
  modal: 103,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}

const headings = {
  fontFamily: fonts[1],
  fontWeight: 'normal',
  lineHeight: 1.2,
  margin: 0,
  padding: 0,
  color: colors[3],
  '& strong, & b': {
    fontWeight: 'bold',
  },
  '& p': {
    margin: 0,
  },
}

const typography = {
  general: {
    extend: general,
  },
  headings: {
    extend: headings,
  },
}

const wrapper = {
  padding: [0, '5.5vw'],
}

const flex = {
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  horizontalCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
}

const button = {
  color: colors[0],
  padding: [15, 40, 11],
  lineHeight: 1.1,
  fontSize: 18,
  borderRadius: 30,
  display: 'inline-block',
  textAlign: 'center',
  position: 'relative',
  zIndex: 1,
  overflow: 'hidden',
  transition: 'color .2s ease-out, background-color .3s ease-out',
  background: colors[1],
  '&:hover': {
    background: colors[3],
    color: colors[1],
  },
}

const link = {
  position: 'relative',
  lineHeight: 1,
  paddingTop: 7,
  paddingBottom: 7,
  color: colors[1],
  textTransform: 'uppercase',
  fontSize: 10,
  letterSpacing: '0.22em',
  cursor: 'pointer',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 5,
    left: 0,
    width: '100%',
    height: 1,
    background: 'currentColor',
    transition: `transform 0.3s ${easings['power4.out']}`,
    transform: 'scaleX(0)',
    transformOrigin: '100% 0',
  },
  '&:hover': {
    '&::before': {
      transform: 'scaleX(1)',
      transformOrigin: '0% 0',
    },
  },
}

export const theme = {
  media,
  mediaInverse,
  colors,
  zindex,
  wrapper,
  easings,
  fonts,
  typography,
  button,
  link,
  detect: {
    hasTouchWin: navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1,
    hasPointer: !!window.navigator.msPointerEnabled,
    hasTouch: 'ontouchstart' in document,
    passive: supportPassive(),
    isIos: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
    isSafari:
      navigator.vendor
      && navigator.vendor.indexOf('Apple') > -1
      && navigator.userAgent
      && navigator.userAgent.indexOf('CriOS') === -1
      && navigator.userAgent.indexOf('FxiOS') === -1,
    isIE11: !!window.MSInputMethodContext && !!document.documentMode,
    isNativeScroll:
      ('ontouchstart' in document && window.innerWidth <= 1085)
      || (!!window.MSInputMethodContext && !!document.documentMode)
      || window.innerWidth < 1024,
    dom2webgl:
      !(!!window.MSInputMethodContext && !!document.documentMode)
      && !('ontouchstart' in document && window.innerWidth <= 1085), // Non IE11 e Non touch con larghezza > 1085
  },
  maxWidth: 1600,
  padding,
  paddingMobile,
  header: {
    top: 35,
    bottom: 20,
    logo: {
      width: 150,
      height: 40,
    },
    burger: {
      wrap: 40,
      width: 12,
      height: 10,
    },
  },
  animations: {
    ease: 'cubic-bezier(0.650, 0.075, 0.400, 0.935)',
    time: '0.6s',
  },
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  breakpoints: generateBreakPoints({
    values: sizes,
  }),
  flex,
}
