import { useCallback, createContext, useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import usePrevious from '@/hooks/usePrevious'
import useStore from '@/base/zustand'
import * as contentActions from '@/actions/content'

const Context = createContext({})
const { Provider, Consumer } = Context

const ContextComponent = ({
  children,
}) => {
  const [layout, setLayout] = useState('dark')
  const { setStage } = useStore()
  const [isFullscreenMode, setFullscreenMode] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerRef, setHeaderRef] = useState({})
  const [cookieRef, setCookieRef] = useState({})
  const [isBackButtonVisible, setBackButtonVisible] = useState(false)
  const [backButtonLink, setBackButtonLink] = useState('')
  const [headerInverse, setHeaderInverse] = useState(false)
  const [pageAnimationReady, setPageAnimationReady] = useState(false)
  const [currentPresentationIndex, setCurrentPresentationIndex] = useState(0)
  const [scrollY, setScrollY] = useState(0)

  const { pathname } = useSelector((state) => ({
    pathname: state.router.location.pathname,
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  const dispatch = useDispatch()
  const resetAllPosts = useCallback(() => dispatch(contentActions.resetAllPosts()), [dispatch])

  useEffect(() => {
    if (prevPathname !== undefined && prevPathname !== pathname) {
      setPageAnimationReady(false)
      setStage(0)
      setCurrentPresentationIndex(0)
      resetAllPosts()
    }

    // ScrollY su scholar
    if (prevPathname !== undefined) {
      if (prevPathname.indexOf('scholar') === -1 && prevPathname.indexOf('science') === -1) {
        setScrollY(0)
      }
    }
  }, [pathname])

  return (
    <Provider value={{
      layout,
      setLayout,
      isFullscreenMode,
      setFullscreenMode,
      headerHeight,
      setHeaderHeight,
      setHeaderRef,
      headerRef,
      setCookieRef,
      cookieRef,
      setHeaderInverse,
      headerInverse,
      setPageAnimationReady,
      pageAnimationReady,
      isBackButtonVisible,
      setBackButtonVisible,
      backButtonLink,
      setBackButtonLink,
      setCurrentPresentationIndex,
      currentPresentationIndex,
      scrollY,
      setScrollY,
    }}
    >
      { children }
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default ContextComponent
