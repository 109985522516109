/* eslint-disable prefer-destructuring */
const style = (theme) => {
  const transition = `all 1s ${theme.easings['power4.out']}`

  const output = {
    root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: theme.zindex.header,
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      pointerEvents: 'none',
      background: 'transparent',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 0,
        top: 0,
        left: 0,
        width: '100%',
        height: '140%',
        transition: 'transform .2s ease-out',
        transform: 'translateY(-100%)',
        backdropFilter: 'blur(6px)',
        WebkitMaskImage: 'linear-gradient(rgba(0, 0, 0, 1.0) 50%, transparent 99%)',
        // background: 'red',
      },
    },
    minimize: {
      background: 'transparent',
      '& $logo': {
        opacity: (props) => (props.isMenuOpen ? 1 : 0),
      },
      '& $headerNav': {
        opacity: 0,
      },
    },
    background: {
      '&:before': {
        transform: (props) => (props.isMenuOpen ? 'translateY(-100%)' : 'translateY(-10%)'),
      },
      // background: (props) => (props.headerInverse && !props.isMenuOpen ? `linear-gradient(${theme.getRgba(theme.colors[1], 0.4)} 60%, transparent)` : theme.colors[0]),
      // WebkitMaskImage: 'linear-gradient(rgba(0, 0, 0, 1.0) 72%, transparent)',
    },
    container: {
      margin: '0 auto',
      paddingTop: theme.header.top,
      paddingBottom: theme.header.bottom,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition,
    },
    logo: {
      display: 'inline-block',
      pointerEvents: 'all',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '& svg': {
        transition,
        fill: (props) => (props.headerInverse && !props.isMenuOpen ? theme.colors[0] : theme.colors[1]),
      },
      '& g': {
        transition: `opacity 0.3s ${theme.easings['power4.out']}`,
      },
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'all',
      height: '100%',
    },
    headerNav: {
      display: 'flex',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '& li': {
        marginRight: 60,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
      },
      '& a': {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1,
        paddingTop: 7,
        paddingBottom: 7,
        color: (props) => (props.headerInverse ? theme.colors[0] : theme.colors[1]),
        textShadow: (props) => (props.headerInverse ? '1px 1px 1px rgba(255, 255, 255, .7)' : '1px 1px 1px rgba(0, 0, 0, .2)'),
        textTransform: 'uppercase',
        fontSize: 10,
        letterSpacing: '0.22em',
      },
    },
    btn: {
      backgroundColor: theme.colors[3],
      color: `${theme.colors[1]} !important`,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 6,
      fontSize: 10,
      textTransform: 'uppercase',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '&:hover': {
        backgroundColor: theme.colors[4],
      },
    },
    burgerButton: {
      width: theme.header.burger.wrap,
      height: theme.header.burger.wrap,
      background: (props) => (props.headerInverse ? theme.colors[0] : theme.colors[1]),
      borderRadius: '50%',
      cursor: 'pointer',
      letterSpacing: 1,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 2,
      justifyContent: 'center',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      position: 'relative',
      zIndex: 1,
      '&.isMenuOpen': {
        background: theme.colors[1],
        '&:before': {
          background: theme.colors[1],
        },
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        borderRadius: '50%',
        background: (props) => (props.headerInverse ? theme.colors[0] : theme.colors[1]),
        width: '100%',
        height: '100%',
        transition: `all 1s ${theme.easings['power3.out']}`,
      },
      '@media (hover:hover)': {
        '&:hover': {
          '&:before': {
            transform: 'scale(1.2)',
          },
        },
      },
    },
    backWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
    },
    back: {
      position: 'relative',
      lineHeight: 1,
      paddingTop: 7,
      paddingBottom: 7,
      color: (props) => (props.headerInverse ? theme.colors[0] : theme.colors[1]),
      textTransform: 'uppercase',
      fontSize: 10,
      letterSpacing: '0.22em',
      cursor: 'pointer',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 5,
        left: 0,
        width: '100%',
        height: 1,
        background: (props) => (props.headerInverse ? theme.colors[0] : theme.colors[1]),
        transition: `transform 0.3s ${theme.easings['power4.out']}`,
        transform: 'scaleX(0)',
        transformOrigin: '100% 0',
      },
      '&:hover': {
        '&::before': {
          transform: 'scaleX(1)',
          transformOrigin: '0% 0',
        },
      },
    },
    mainLink: {
      position: 'relative',
      lineHeight: 1,
      paddingTop: 7,
      paddingBottom: 7,
      color: (props) => (props.headerInverse ? theme.colors[0] : theme.colors[1]),
      textTransform: 'uppercase',
      fontSize: 10,
      letterSpacing: '0.22em',
      cursor: 'pointer',
      transition: 'opacity .3s ease-out',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 5,
        left: 0,
        width: '100%',
        height: 1,
        background: (props) => (props.headerInverse ? theme.colors[0] : theme.colors[1]),
        transition: `transform 0.3s ${theme.easings['power4.out']}`,
        transform: 'scaleX(0)',
        transformOrigin: '100% 0',
      },
      '&:hover': {
        '&::before': {
          transform: 'scaleX(1)',
          transformOrigin: '0% 0',
        },
      },
    },
    hide: {
      opacity: 0,
      pointerEvents: 'none',
    },
  }

  output[theme.mediaInverse.md] = {
    headerNav: {
      '& a': {
        display: 'none',
      },
    },
    btn: {
      display: 'block !important',
    },
  }

  output[theme.mediaInverse.sm] = {
    container: {
      paddingTop: 20,
      // backdropFilter: 'blur(6px)',
      // WebkitMaskImage: 'linear-gradient(rgba(0, 0, 0, 1.0) 72%, transparent)',
    },
    headerNav: {
      display: 'none',
    },
    backWrapper: {
      top: 10,
      right: 88,
      position: 'absolute',
      transform: 'none',
      left: 'auto',
    },
    wrapper: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  }

  return output
}

export default style
