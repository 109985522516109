import { useState } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import Slider from 'react-slick'
import CarouselPagination from '@/components/CarouselPagination'
import ParallaxImg from '@/components/ParallaxImg'
import style from './style'

const useStyles = createUseStyles(style)

const Arrow = (props) => {
  const { className, onClick } = props
  return (
    <button
      className={className}
      onClick={onClick}
    >
      <svg><use xlinkHref="#ico-arrow-left" /></svg>
    </button>
  )
}

const Carousel = ({
  className,
  arrows,
  centerMode,
  dots,
  imagesList,
  pagination,
  parallax,
  variableWidth,
}) => {
  const classes = useStyles()
  const [currentSlide, setCurrentSlide] = useState(0)

  const renderSimpleGallery = () => {
    let slickItems = null

    if (imagesList.length > 0) {
      slickItems = imagesList.map((i, index) => (
        <div
          key={index.toString()}
          tabIndex={index.toString()}
          data-image={index}
          className={`${classes.carouselItem} Carousel__carouselItem`}
        >
          <div className={`${classes.carouselImg} Carousel__carouselImg`} style={{ '--ratio': `${i.height / i.width}%` }}>
            {parallax ? (
              <ParallaxImg
                img={i.src}
              />
            ) : (
              <img src={i.src} alt={i.caption} width={i.width} height={i.height} />
            )}
          </div>
        </div>
      ))
    }

    const slickSettings = {
      dots,
      arrows,
      speed: 600,
      cssEase: 'cubic-bezier(0, 0, 0.17, 0.97)',
      touchThreshold: 20,
      infinite: false,
      slidesToShow: 1,
      centerMode,
      variableWidth,
      nextArrow: <Arrow />,
      prevArrow: <Arrow />,
      afterChange: (nextIndex) => {
        setCurrentSlide(nextIndex)
      },
    }

    return slickItems && (
      <div
        className={classNames({
          [classes.root]: true,
          [className]: className,
        })}
      >
        {pagination && (
          <div className={classes.header}>
            <div className={classes.counter}>
              <CarouselPagination
                items={imagesList}
                active={currentSlide}
              />
            </div>
          </div>
        )}
        <div className={classes.carousel}>
          <Slider
            dots={slickSettings.dots}
            arrows={slickSettings.arrows}
            speed={slickSettings.speed}
            cssEase={slickSettings.cssEase}
            touchThreshold={slickSettings.touchThreshold}
            infinite={slickSettings.infinite}
            slidesToShow={slickSettings.slidesToShow}
            centerMode={slickSettings.centerMode}
            variableWidth={slickSettings.variableWidth}
            afterChange={slickSettings.afterChange}
            nextArrow={slickSettings.nextArrow}
            prevArrow={slickSettings.prevArrow}
            className={`${classes.slick} Carousel__slick`}
          >
            {slickItems}
          </Slider>
        </div>
      </div>
    )
  }

  return renderSimpleGallery()
}

Carousel.defaultProps = {
  arrows: false,
  centerMode: false,
  dots: true,
  pagination: false,
  parallax: false,
  variableWidth: false,
}

export default Carousel
