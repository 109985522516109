import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import useScrollTrigger from '@/hooks/useScrollTrigger'
import style from './style'

const useStyles = createUseStyles(style)

const ParallaxImg = ({
  img,
  offset,
  title,
}) => {
  const classes = useStyles({ offset })
  const { animationReady } = useScrollTrigger()
  const $img = useRef()

  useEffect(() => {
    if (animationReady) {
      gsap.to($img.current, {
        scrollTrigger: {
          trigger: $img.current,
          scrub: true,
          start: 'top 100%',
          end: 'bottom 0%',
          once: false,
        },
        y: offset,
        ease: 'none',
      })
    }
  }, [animationReady])

  return (
    <img
      className={classes.image}
      src={img}
      alt={title}
      ref={$img}
    />
  )
}

ParallaxImg.defaultProps = {
  offset: '10%',
}

export default ParallaxImg
