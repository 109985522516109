/* eslint-disable no-alert */
import { Workbox } from 'workbox-window'

const registerServiceWorker = () => {
  if (process.env.NODE_ENV !== 'production') return
  // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js')

    wb.addEventListener('installed', (event) => {
      /**
       * We have the condition - event.isUpdate because we don't want to show
       * this message on the very first service worker installation,
       * only on the updated
       */
      if (event.isUpdate) {
        if (window.confirm('New app update is available!. Click OK to refresh')) {
          window.location.reload()
        }
      }
    })
    wb.register()
  }
}

export default registerServiceWorker
