const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: theme.colors[4],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    logo: {
      pointerEvents: 'none',
      '& svg': {
        fill: theme.colors[1],
      },
    },
    hide: {
      pointerEvents: 'none',
    },
    logoContainer: {
      animation: '$fadeIn 0.4s ease-in-out forwards',
    },
    spinnerContainer: {
      animation: '$fadeIn 0.4s ease-in-out forwards',
    },
    spinner: {
      marginTop: 15,
      width: 150,
      height: 1,
      background: theme.colors[5],
      borderRadius: 20,
      position: 'relative',
      overflow: 'hidden',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 0,
        height: '100%',
        background: theme.colors[1],
        animation: '$loading 1s ease-in-out infinite',
      },
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes loading': {
      '50%': { width: '100%' },
      '100%': { width: 0, right: 0, left: 'unset' },
    },
  }
  return output
}

export default style
