import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import DelayLink from '@/components/DelayLink'
import { getSlug } from '@/utils/path'
import { decodeEntities } from '@/utils/decodeEntities'
import style from './style'

const useStyles = createUseStyles(style)

const NavLink = ({
  item,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const $bar = useRef()
  const $text = useRef()
  const dir = useRef('top')

  gsap.defaults({
    duration: 0.4,
    ease: 'power3.out',
  })

  const handleHover = (bool) => {
    gsap.set($bar.current, {
      transformOrigin: dir.current === 'top' ? '0% 0%' : '0% 100%',
    })
    gsap.to($bar.current, {
      scaleY: bool ? 1 : 0,
    })

    gsap.to($text.current, {
      x: bool ? 30 : 0,
    })
  }

  return (
    <DelayLink
      className={classNames({
        [classes.root]: true,
        [classes.active]: getSlug(item.url) === getSlug(location.pathname),
      })}
      to={item.url}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <span className={classes.bar} ref={$bar} />
      <span className={classes.text} ref={$text}>{decodeEntities(item.title)}</span>
      <span
        className={classNames({
          [classes.link]: true,
          [classes.top]: true,
        })}
        onMouseEnter={() => dir.current = 'top'}
        onMouseLeave={() => dir.current = 'top'}
      />
      <span
        className={classNames({
          [classes.link]: true,
          [classes.bottom]: true,
        })}
        onMouseEnter={() => dir.current = 'bottom'}
        onMouseLeave={() => dir.current = 'bottom'}
      />
    </DelayLink>
  )
}

export default NavLink
