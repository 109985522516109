import { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Context as LoadingContext } from '@/context/loading'
import style from './style'

const useStyles = createUseStyles(style)

const Spinner = () => {
  const { isLoading } = useContext(LoadingContext)
  const classes = useStyles()

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.visible]: isLoading,
      })}
    >
      <div className={classes.spinnerContainer}>
        <div
          className={classes.spinner}
        />
      </div>
    </div>
  )
}

export default Spinner
