const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100vw',
      minHeight: 247,
      background: theme.colors[1],
      zIndex: theme.zindex.bottomNav,
      padding: [0, '4.16vw'],
      paddingLeft: 'calc(5.5vw + 41px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    isOpen: {
      '& $close': {
        transform: 'rotate(0deg)',
      },
    },
    close: {
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      left: '5.5vw',
      bottom: 100,
      width: 41,
      height: 41,
      background: theme.colors[4],
      borderRadius: '50%',
      transition: `all 0.7s ${theme.easings['power3.out']}`,
      transform: 'rotate(60deg)',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        borderRadius: '50%',
        background: theme.colors[0],
        width: '100%',
        height: '100%',
        transition: `all 1s ${theme.easings['power3.out']}`,
      },
      '@media (hover:hover)': {
        '&:hover': {
          '&:before': {
            transform: 'scale(1.2)',
          },
        },
      },
      '& svg': {
        width: 12,
        height: 12,
        fill: theme.colors[1],
      },
      '&:hover': {
        background: theme.colors[3],
      },
    },
    wrapper: {
      height: '100%',
      position: 'relative',
      margin: 0,
      color: theme.colors[4],
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
    },
    visible: {
      opacity: 1,
    },
    childPageNavigation: {
      columns: 2,
      marginLeft: 'calc(8.32vw + 41px)',
      '& li': {
        padding: [4, 0],
        '& br': {
          display: 'inline',
          content: '""',
          padding: [0, '0.1em'],
        },
      },
      '& button': {
        fontFamily: theme.fonts[1],
        cursor: 'pointer',
        transition: 'color 0.3s ease-out',
        display: 'block',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
          '& small': {
            color: theme.colors[1],
            '& svg': {
              fill: theme.colors[0],
              transform: 'rotate(180deg)',
            },
          },
        },
      },
      '& small': {
        zIndex: '1',
        position: 'relative',
        display: 'inline-block',
        marginRight: '10px',
        width: '14px',
        textAlign: 'center',
        transition: 'color .2s ease-out',
        '& svg': {
          top: '-5px',
          fill: theme.colors[1],
          left: '-5px',
          width: '25px',
          height: '25px',
          stroke: theme.colors[0],
          position: 'absolute',
          zIndex: -1,
          strokeWidth: '2',
          transform: 'rotate(30deg)',
          transformOrigin: 'center',
          transition: 'fill .2s ease-out, transform .6s ease-out',
        },
      },
    },
  }

  output[theme.mediaInverse.md] = {
    root: {
    },
  }

  output[theme.mediaInverse.md] = {
    root: {
    },
  }

  return output
}

export default style
