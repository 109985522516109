const style = {
  image: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: (props) => `calc(100% + ${props.offset})`,
    bottom: 0,
    left: 0,
    objectFit: 'cover',
    objectPosition: 'center center',
    fontFamily: "'object-fit: cover; object-poistion: center center;'",
  },
}
export default style
