export const LOADING_SET_READY = 'LOADING_SET_READY'
export const LOADING_SET_INC_STATUS = 'LOADING_SET_INC_STATUS'
export const LOADING_FIRST_LOAD = 'LOADING_FIRST_LOAD'
export const LOADING_SET_COOKIE = 'LOADING_SET_COOKIE'
export const LOADING_SET_REAL_LINK = 'LOADING_SET_REAL_LINK'
export const LOADING_SET_SITE_LOADED = 'LOADING_SET_SITE_LOADED'

export const PAGE_ADD_CONTENT = 'PAGE_ADD_CONTENT'
export const PAGE_ADD_MEDIA = 'PAGE_ADD_MEDIA'
export const PAGE_ADD_POST = 'PAGE_ADD_POST'
export const PAGE_ADD_FORM = 'PAGE_ADD_FORM'
export const PAGE_SEND_FORM = 'PAGE_SEND_FORM'
export const PAGE_STATUS_FORM = 'PAGE_STATUS_FORM'
export const PAGE_LIST_CONTENT = 'PAGE_LIST_CONTENT'
export const PAGE_MERGE_LIST_CONTENT = 'PAGE_MERGE_LIST_CONTENT'
export const PAGE_LIST_TAXONOMIES = 'PAGE_LIST_TAXONOMIES'
export const PAGE_SET_NEWSPAGINATION = 'PAGE_SET_NEWSPAGINATION'

export const PAGE_SET_LAYOUT = 'PAGE_SET_LAYOUT'
export const PAGE_SET_TRANSITION = 'PAGE_SET_TRANSITION'
export const PAGE_SET_SHOW = 'PAGE_SET_SHOW'
export const PAGE_SET_CURRENT = 'PAGE_SET_CURRENT'

export const MODAL_SET_OPEN = 'MODAL_SET_OPEN'
export const MODAL_SET_IMAGE = 'MODAL_SET_IMAGE'
export const MODAL_SET_TEXT = 'MODAL_SET_TEXT'
export const MODAL_SET_DATA = 'MODAL_SET_DATA'
export const SET_MODAL_STORE_ID = 'SET_MODAL_STORE_ID'

const OPEN_LAYER = 'OPEN_LAYER'
const CLOSE_LAYER = 'CLOSE_LAYER'
const CLOSE_ALL_LAYER = 'CLOSE_ALL_LAYER'
const SET_SIDE_RIGHT_LAYER_ID = 'SET_SIDE_RIGHT_LAYER_ID'

const REQUEST_ROUTING = 'REQUEST_ROUTING'
const RECEIVE_ROUTING = 'RECEIVE_ROUTING'

const REQUEST_OPTIONS = 'REQUEST_OPTIONS'
const RECEIVE_OPTIONS = 'RECEIVE_OPTIONS'

const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM'
const SENDED_CONTACT_FORM = 'SENDED_CONTACT_FORM'

const REQUEST_FORM = 'REQUEST_FORM'
const RECEIVE_FORM = 'RECEIVE_FORM'

const REQUEST_NAV = 'REQUEST_NAV'
const RECEIVE_NAV = 'RECEIVE_NAV'

const REQUEST_PAGE = 'REQUEST_PAGE'
const RECEIVE_PAGE = 'RECEIVE_PAGE'

const REQUEST_POSTS = 'REQUEST_POSTS'
const RECEIVE_POSTS = 'RECEIVE_POSTS'
const RESET_POSTS = 'RESET_POSTS'
const REQUEST_POST = 'REQUEST_POST'
const RECEIVE_POST = 'RECEIVE_POST'
const RESET_CURRENT_POST = 'RESET_CURRENT_POST'

const REQUEST_CPTS = 'REQUEST_CPTS'
const RECEIVE_CPTS = 'RECEIVE_CPTS'
const REQUEST_CPT = 'REQUEST_CPT'
const RECEIVE_CPT = 'RECEIVE_CPT'
const RESET_CURRENT_CPT = 'RESET_CURRENT_CPT'

const REQUEST_PAPERS = 'REQUEST_PAPERS'
const RECEIVE_PAPERS = 'RECEIVE_PAPERS'

const REQUEST_TAXONOMIES = 'REQUEST_TAXONOMIES'
const RECEIVE_TAXONOMIES = 'RECEIVE_TAXONOMIES'

export {
  OPEN_LAYER,
  CLOSE_LAYER,
  CLOSE_ALL_LAYER,
  SET_SIDE_RIGHT_LAYER_ID,
  REQUEST_ROUTING,
  RECEIVE_ROUTING,
  REQUEST_OPTIONS,
  RECEIVE_OPTIONS,
  SEND_CONTACT_FORM,
  SENDED_CONTACT_FORM,
  REQUEST_FORM,
  RECEIVE_FORM,
  REQUEST_NAV,
  RECEIVE_NAV,
  REQUEST_PAGE,
  RECEIVE_PAGE,
  REQUEST_POSTS,
  RECEIVE_POSTS,
  RESET_POSTS,
  REQUEST_POST,
  RECEIVE_POST,
  REQUEST_CPTS,
  RECEIVE_CPTS,
  REQUEST_CPT,
  RECEIVE_CPT,
  REQUEST_PAPERS,
  RECEIVE_PAPERS,
  RESET_CURRENT_CPT,
  RESET_CURRENT_POST,
  REQUEST_TAXONOMIES,
  RECEIVE_TAXONOMIES,
}
