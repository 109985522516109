import { lazy } from 'react'
import { internazionalizePath, getPathFromCpt } from '@/utils/path'

const Home = lazy(() => import(/* webpackChunkName: "home" */'@/pages/Home'))
const Presentation = lazy(() => import(/* webpackChunkName: "presentation" */'@/pages/Presentation'))
const Technology = lazy(() => import(/* webpackChunkName: "technology" */'@/pages/Technology'))
const About = lazy(() => import(/* webpackChunkName: "about" */'@/pages/About'))
const Pipeline = lazy(() => import(/* webpackChunkName: "pipeline" */'@/pages/Pipeline'))
const Scholar = lazy(() => import(/* webpackChunkName: "scholar" */'@/pages/Scholar'))
const ScholarPost = lazy(() => import(/* webpackChunkName: "scholarpost" */'@/pages/ScholarPost'))
const Faqs = lazy(() => import(/* webpackChunkName: "faqs" */'@/pages/Faqs'))
const People = lazy(() => import(/* webpackChunkName: "people" */'@/pages/People'))
const Contact = lazy(() => import(/* webpackChunkName: "contact" */'@/pages/Contact'))
const ArchivePosts = lazy(() => import(/* webpackChunkName: "archiveposts" */'@/pages/ArchivePosts'))
const ArchiveTaxonomy = lazy(() => import(/* webpackChunkName: "archivetaxonomy" */'@/pages/ArchiveTaxonomy'))
const Post = lazy(() => import(/* webpackChunkName: "post" */'@/pages/Post'))
const Generic = lazy(() => import(/* webpackChunkName: "generic" */'@/pages/Generic'))
const NotFound = lazy(() => import(/* webpackChunkName: "notfound" */'@/pages/NotFound'))

export default (routing) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'home')?.slug} />,
  },
  {
    key: 1,
    path: internazionalizePath(
      getPathFromCpt(routing, 'presentation'),
    ),
    exact: true,
    render: () => <Presentation />,
  },
  {
    key: 4,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_post'),
    ),
    exact: true,
    render: () => <ArchivePosts />,
  },
  {
    key: 5,
    path: internazionalizePath(
      getPathFromCpt(routing, 'post'),
    ),
    render: () => <Post />,
    transition: 'slideUp',
  },
  {
    key: 6,
    path: internazionalizePath(
      getPathFromCpt(routing, 'technology'),
    ),
    exact: true,
    render: () => <Technology />,
  },
  {
    key: 7,
    path: internazionalizePath(
      getPathFromCpt(routing, 'about'),
    ),
    exact: true,
    render: () => <About />,
  },
  {
    key: 8,
    path: internazionalizePath(
      getPathFromCpt(routing, 'people', true),
    ),
    exact: true,
    render: () => <People />,
  },
  {
    key: 9,
    path: internazionalizePath(
      getPathFromCpt(routing, 'faqs'),
    ),
    exact: true,
    render: () => <Faqs />,
  },
  {
    key: 10,
    path: internazionalizePath('/science/:id'),
    exact: true,
    render: () => <ScholarPost />,
  },
  {
    key: 11,
    path: internazionalizePath(
      getPathFromCpt(routing, 'scholar'),
    ),
    exact: true,
    render: () => <Scholar />,
  },
  {
    key: 12,
    path: internazionalizePath(
      getPathFromCpt(routing, 'pipeline'),
    ),
    exact: true,
    render: () => <Pipeline />,
  },
  {
    key: 13,
    path: internazionalizePath(
      getPathFromCpt(routing, 'contact'),
    ),
    render: () => <Contact />,
  },
  {
    key: 14,
    path: internazionalizePath(
      getPathFromCpt(routing, 'generic'),
    ),
    render: () => <Generic />,
  },
  {
    key: 15,
    path: internazionalizePath(
      getPathFromCpt(routing, 'category'),
    ),
    render: () => <ArchiveTaxonomy />,
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
