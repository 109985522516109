const style = (theme) => {
  const output = {
    root: {
      pointerEvents: 'none',
      backgroundColor: theme.getRgba(theme.colors[0], 0.5),
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100vw',
      height: '100vh',
      fallback: {
        height: 'calc(var(--vh) * 100)',
      },
      backdropFilter: 'blur(6px)',
      zIndex: theme.zindex.overlay,
    },
    visible: {
      pointerEvents: 'all',
      cursor: 'pointer',
    },
  }

  return output
}
export default style
