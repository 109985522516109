const style = (theme) => {
  const output = {
    root: {
      extend: theme.smallWrapper,
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: theme.colors[0],
      color: theme.colors[1],
      width: '100%',
      zIndex: theme.zindex.header,
      paddingTop: 50,
      paddingBottom: 50,
      fontSize: 14,
      fontFamily: theme.fonts[0],
      fontWeight: 400,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& a': {
        extend: theme.button,
        fontSize: 12,
        marginTop: 10,
        background: theme.colors[3],
        color: theme.colors[1],
        minWidth: 'none',
        paddingLeft: 20,
        paddingRight: 20,
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        fontFamily: theme.fonts[0],
      },
    },
  }

  output[theme.mediaInverse.sm] = {
    root: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  }

  return output
}

export default style
