const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '52vw',
      height: '100%',
      zIndex: theme.zindex.sideRight,

    },
    close: {
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      top: 34,
      right: '5.5vw',
      width: 41,
      height: 41,
      background: theme.colors[4],
      borderRadius: '50%',
      transition: `all 0.3s ${theme.easings['power4.out']}`,
      '& svg': {
        width: 12,
        height: 12,
        fill: theme.colors[1],
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        borderRadius: '50%',
        background: theme.colors[0],
        width: '100%',
        height: '100%',
        transition: `all 1s ${theme.easings['power3.out']}`,
      },
      '@media (hover:hover)': {
        '&:hover': {
          background: theme.colors[3],
          '&:before': {
            transform: 'scale(1.2)',
            background: theme.colors[3],
          },
        },
      },
    },
    wrapper: {
      height: '100%',
      background: theme.colors[1],
      borderTopLeftRadius: '22px',
      borderBottomLeftRadius: '22px',
      position: 'relative',
      margin: 0,
      color: theme.colors[4],
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
      overflow: 'auto',
      overflowX: 'hidden',
      paddingLeft: '4.16vw',
      paddingRight: '4.16vw',
      paddingTop: '9vh',
    },
    scrollable: {
      width: '100%',
      '& > *': {
        marginBottom: 80,
      },
    },
    visible: {
      opacity: 1,
    },
    pretitle: {
      fontSize: 10,
      textTransform: 'uppercase',
      letterSpacing: '2px',
      marginBottom: '25px !important',
    },
    title: {
      fontSize: 45,
      textTransform: 'uppercase',
      fontFamily: theme.fonts[1],
    },
    text: {
      fontSize: 18,
    },
    gallery: {
      width: '100%',
      '& .slick-list': {
        width: '100%',
        margin: '0 auto',
      },
      '& .slick-arrow': {
        zIndex: 1,
        backgroundColor: theme.colors[3],
        '& svg': {
          fill: theme.colors[1],
        },
      },
      '& .slick-prev': {
        left: 25,
      },
      '& .slick-next': {
        right: 25,
      },
      '& .Carousel__carouselItem': {
        padding: 0,
        verticalAlign: 'middle',
      },
      '& .Carousel__carouselImg': {
        position: 'relative',
        zIndex: 1,
        paddingBottom: '64%',
        borderRadius: 22,
        overflow: 'hidden',
        '& img': {
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: 1,
          objectFit: 'cover',
          top: 0,
          left: 0,
        },
      },
    },
    image: {
      width: '100%',
      borderRadius: 22,
      overflow: 'hidden',
    },
  }

  output[theme.mediaInverse.lg] = {
    root: {
      width: '70vw',
    },
  }

  output[theme.mediaInverse.sm] = {
    root: {
      width: '100vw',
    },
    wrapper: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    title: {
      fontSize: 32,
    },
    text: {
      fontSize: 17,
    },
    close: {
      top: 20,
    },
  }

  return output
}

export default style
