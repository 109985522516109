const SVGSprite = () => {
  return (
    <svg style={{ display: 'none' }}>
      <symbol id="ico-close" viewBox="0 0 20 20">
        <path d="M20 1.42L18.58 0 10 8.58 1.42 0 0 1.42 8.58 10 0 18.58 1.42 20 10 11.42 18.58 20 20 18.58 11.42 10z" />
      </symbol>
      <symbol id="ico-arrow-left" viewBox="0 0 12 16">
        <path d="M11.633,2.828 L8.766,-0.000 L0.659,7.999 L0.660,8.000 L0.659,8.001 L8.766,16.000 L11.633,13.171 L6.391,8.000 L11.633,2.828 Z" />
      </symbol>
      <symbol id="ico-arrow-right" viewBox="0 0 6.1 7">
        <path d="M2.63 7l.61-.48-2-2.64H6.1V3.1H1.2L3.26.48 2.64 0 .09 3.26a.37.37 0 000 .48z" transform="rotate(180)" transform-origin="center center" />
      </symbol>
      <symbol id="ico-arrow" viewBox="0 0 5.7 8.5">
        <path d="M4.2 2.8L1.4 0 0 1.4l2.8 2.8L0 7.1l1.4 1.4 2.8-2.8 1.5-1.5z" />
      </symbol>
      <symbol id="ico-play" viewBox="0 0 31.5 36.37">
        <path d="M30.5 18.19l-15 8.66-15 8.66V.87l15 8.66 15 8.66z" />
      </symbol>
      <symbol id="ico-fullscreen" viewBox="0 0 17 17">
        <path d="M0 7V0h7v2H2v5zm10-7h7v7h-2V2h-5zm7 10v7h-7v-2h5v-5zM7 17H0v-7h2v5h5z" />
      </symbol>
      <symbol id="ico-search" viewBox="0 0 71.3 71.3">
        <path d="M71.3 65.6L56.1 50.4a31 31 0 006.5-19.1 31.3 31.3 0 10-31.3 31.3 31 31 0 0019.1-6.5l15.2 15.2zM8 31.3a23.3 23.3 0 1123.3 23.2A23.32 23.32 0 018 31.3z" />
      </symbol>
      <symbol id="ico-shopping-bag" viewBox="0 0 20 20">
        <path d="M18.4 3.32h-4.31a4.11 4.11 0 00-8.09 0H1.72V20H18.4zM10.06.91a3.22 3.22 0 013.1 2.41H7A3.23 3.23 0 0110.06.91zm7.43 18.18H2.63V4.23H6V6.1h.91V4.23h6.4V6.1h.91V4.23h3.32z" />
      </symbol>
      <symbol id="ico-heart" viewBox="0 0 14 14">
        <path d="M9.48 1.65A3.92 3.92 0 007 2.61a3.86 3.86 0 00-2.48-1A3.47 3.47 0 001 5v.07c0 2.36 2.05 4.19 4.9 6.7l.22.22a1.24 1.24 0 001.75 0l.23-.22C10.94 9.34 13 7.53 13 5.15a3.47 3.47 0 00-3.44-3.5h-.08z" />
      </symbol>
      <symbol id="ico-download" viewBox="0 0 21 21">
        <path d="M10.500,21.000 C4.700,21.000 0.000,16.300 0.000,10.500 C0.000,10.200 0.200,10.000 0.500,10.000 C0.800,10.000 1.000,10.200 1.000,10.500 C1.000,15.700 5.300,20.000 10.500,20.000 C15.700,20.000 20.000,15.700 20.000,10.500 C20.000,10.200 20.200,10.000 20.500,10.000 C20.800,10.000 21.000,10.200 21.000,10.500 C21.000,16.300 16.300,21.000 10.500,21.000 ZM10.900,13.900 C10.800,13.900 10.800,14.000 10.700,14.000 C10.600,14.100 10.400,14.100 10.300,14.000 C10.200,14.000 10.100,13.900 10.100,13.900 L5.200,8.800 C5.000,8.600 5.000,8.300 5.200,8.100 C5.400,7.900 5.700,7.900 5.900,8.100 L10.000,12.300 L10.000,0.500 C10.000,0.200 10.300,0.000 10.600,0.000 C10.900,0.000 11.100,0.200 11.100,0.500 L11.100,12.300 L15.200,8.200 C15.400,8.000 15.700,8.000 15.900,8.200 C16.100,8.400 16.100,8.700 15.900,8.900 L10.900,13.900 Z" />
      </symbol>
      <symbol id="ico-link" viewBox="0 0 21 21">
        <path d="M7.7 19.638c-1.8 1.8-4.6 1.8-6.4 0s-1.8-4.6 0-6.4l4.3-4.3c1.7-1.7 4.4-1.8 6.1-.2.3.2.3.6.1.9-.2.3-.6.3-.9.1-1.3-1.1-3.2-1.1-4.5.2l-4.3 4.3a3.32 3.32 0 000 4.7c1.3 1.3 3.4 1.3 4.7 0l4-4c.2-.2.6-.2.9 0 .2.2.2.6 0 .9l-4 3.8zm7.7-7.6c-1.7 1.7-4.4 1.8-6.1.2-.3-.2-.3-.6-.1-.9s.6-.3.9-.1c1.3 1.1 3.2 1.1 4.5-.2l4.3-4.3c1.3-1.3 1.3-3.4 0-4.7a3.32 3.32 0 00-4.7 0l-4 4c-.2.2-.6.2-.9 0-.2-.2-.2-.6 0-.9l4-4c1.8-1.8 4.6-1.8 6.4 0s1.8 4.6 0 6.4l-4.3 4.5z" />
      </symbol>
      <symbol id="ico-twitter" viewBox="0 0 512 512">
        <path
          transform="translate(0,50)"
          d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
        />
      </symbol>
      <symbol id="ico-linkedin" viewBox="0 0 512 512">
        <path d="M0 160h114.496v352H0zM426.368 164.128c-1.216-.384-2.368-.8-3.648-1.152-1.536-.352-3.072-.64-4.64-.896-6.08-1.216-12.736-2.08-20.544-2.08-66.752 0-109.088 48.544-123.04 67.296V160H160v352h114.496V320s86.528-120.512 123.04-32v224H512V274.464c0-53.184-36.448-97.504-85.632-110.336z" />
        <circle cx="56" cy="56" r="56" />
      </symbol>
      <symbol id="ico-instagram" viewBox="0 0 64 64">
        <path d="M18.5.3h27.1C55.7.3 64 8.5 64 18.5v27c0 10-8.3 18.2-18.5 18.2h-27C8.3 63.7 0 55.5 0 45.5v-27C0 8.5 8.3.3 18.5.3zm13.3 14.6c9.9 0 17.9 8 17.9 17.9 0 9.9-8 17.9-17.9 17.9-9.9 0-17.9-8-17.9-17.9 0-9.9 8.1-17.9 17.9-17.9zm0 6c6.5 0 11.8 5.3 11.8 11.8s-5.3 11.8-11.8 11.8c-6.5 0-11.8-5.3-11.8-11.8 0-6.5 5.3-11.8 11.8-11.8zm17.3-8.5c1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9zM20.7 5.5h22.6c8.5 0 15.5 6.9 15.5 15.4v22.8c0 8.5-7 15.4-15.5 15.4H20.7C12.2 59 5.2 52 5.2 43.6V20.8c0-8.4 7-15.3 15.5-15.3z" fillRule="evenodd" clipRule="evenodd" />
      </symbol>
      <symbol id="ico-facebook" viewBox="0 0 512 512">
        <path d="M288 176v-64c0-17.664 14.336-32 32-32h32V0h-64c-53.024 0-96 42.976-96 96v80h-64v80h64v256h96V256h64l32-80h-96z" />
      </symbol>
      <symbol id="ico-youtube" viewBox="0 0 24 24">
        <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0C.488 3.45.029 5.804 0 12c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 20.55 23.971 18.196 24 12c-.029-6.185-.484-8.549-4.385-8.816zM9 16V8l8 3.993L9 16z" />
      </symbol>
      <symbol id="ico-mail" viewBox="0 0 64 64">
        <path d="M32,0C14.5,0,0,14.5,0,32s14.5,32,32,32s32-14.5,32-32S49.5,0,32,0z M32,59.7C16.7,59.7,4.3,47.3,4.3,32S16.7,4.3,32,4.3 S59.7,16.7,59.7,32S47.3,59.7,32,59.7z" />
        <path d="M49.7,18.1H14.3c-1.7,0-3,1.3-3,3v21.7c0,1.7,1.3,3,3,3h35.4c1.7,0,3-1.3,3-3V21.1C52.3,19.4,51,18.1,49.7,18.1z M48.4,24.1 v15.3l-9-7.2L48.4,24.1z M26.7,34.8l2.1,2.1c1.7,1.3,3.8,1.3,5.1,0l2.1-2.1l8.5,6.8H17.7L26.7,34.8z M31.8,33.9L18.1,22h27.3 L31.8,33.9L31.8,33.9z M24.1,32.2l-9,7.2V24.1L24.1,32.2z" />
      </symbol>
    </svg>
  )
}
export default SVGSprite
