import {
  RECEIVE_PAGE,
  RECEIVE_POSTS,
  RECEIVE_POST,
  RECEIVE_CPTS,
  RECEIVE_CPT,
  RECEIVE_PAPERS,
  RECEIVE_TAXONOMIES,
  RESET_POSTS,
} from '@/actions/types'

const initialState = {
  pages: {},
  posts: {
    items: [],
    total: 0,
    pages: 1,
  },
  currentPageName: '',
  cpt: {},
  taxonomies: [],
  papers: [],
  totalPapers: 0,
  totalPages: 1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PAGE:
      state = {
        ...state,
        pages: {
          ...state.pages,
          [action.key]: {
            ...action.payload,
            child: action.child || [],
          },
        },
        currentPageName: action.key,
      }
      break
    case RECEIVE_CPTS:
      state = {
        ...state,
        cpt: {
          ...state.cpt,
          [action.key]: action.payload,
        },
      }
      break
    case RECEIVE_CPT:
      state = {
        ...state,
        cpt: {
          ...state.cpt,
          [action.key]: [state.cpt?.[action.key], ...action.payload],
        },
      }
      break
    case RECEIVE_POSTS:
      state = {
        ...state,
        posts: {
          items: action.payload,
          total: action.totalPosts,
          pages: action.totalPages,
        },
      }
      break
    case RECEIVE_POST:
      state = {
        ...state,
        posts: {
          ...state.posts,
          items: action.payload,
        },
      }
      break
    case RECEIVE_PAPERS:
      state = {
        ...state,
        papers: action.payload,
        totalPapers: action.totalPapers,
        totalPages: action.totalPages,
      }
      break
    case RECEIVE_TAXONOMIES:
      state = {
        ...state,
        taxonomies: action.payload,
      }
      break
    case RESET_POSTS:
      state = {
        ...state,
        posts: initialState.posts,
      }
      break
    default:
      return state
  }
  return state
}
