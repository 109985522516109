import { SITE, LANGUAGES } from '@/constants'

export const cleanOrigin = (path = '') => {
  if (path) {
    const origin = SITE || window.location.origin
    const clean = path.replace(origin, '')
    if (clean.startsWith('/')) {
      return clean
    }
    return `/${clean}`
  }
  return ''
}

export const checkEmpty = (el) => {
  return el !== undefined
}

export const isInternal = (to) => {
  if (to) {
    const linkSplit = to.split('/')
    if (to.indexOf(SITE) === 0) return true
    if (to.indexOf('://') === -1) return true
    if (linkSplit[linkSplit.length - 1].indexOf('.') === -1) return false
    return window.location.hostname === to.hostname
  }
  return false
}

export const getSlug = (pathname = '') => {
  const pathArray = pathname.split('/').filter((p) => p !== '')
  return pathArray.slice(pathArray.length - 1)[0]
}

export const slugify = (string) => {
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
  const to = 'aaaaaeeeeeiiiiooooouuuunc------'

  const newText = string.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)),
  )

  return newText
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-y-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // eslint-disable-line
    .replace(/\-\-+/g, '-') // eslint-disable-line
}

export const internazionalizePath = (path, langs = LANGUAGES) => {
  if (langs.length < 2) return path
  const originalPaths = Array.isArray(path) ? path : Array(path)
  const intPaths = originalPaths.reduce((acc, item) => {
    return acc.concat(langs.map((l) => `/${l}${item}`))
  }, [])
  return originalPaths.concat(intPaths)
}

export const getPathFromCpt = (routing, cpt, isCPT) => {
  const newrouting = routing.map((route) => {
    if (route.cpt === cpt && isCPT) return `/${route.slug}/:stub`
    if (route.cpt === cpt) return `/${route.slug}`
    return null
  }).filter((el) => el !== null)
  return newrouting
}
