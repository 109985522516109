import classNames from 'classnames'
import injectSheet from 'react-jss'
import style from './style'

const BannerIE11 = ({
  classes,
  className,
}) => {
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      Your browser is not supported. Update your browser for more security, speed, and the best experience on this site.
      <a href="https://browsehappy.com/" target="_blank">Update</a>
    </div>
  )
}

export default injectSheet(style)(BannerIE11)
