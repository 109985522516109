const global = (theme) => {
  const output = {
    root: {},
    '@global': {
      body: {
        extend: theme.typography.general,
        color: theme.colors[1],
        background: theme.colors[4],
        overflowY: theme.detect.isNativeScroll ? 'visible' : 'hidden',
        overflowX: 'hidden!important',
        '--fontSize': '22px',
        '& a': {
          textDecoration: 'none',
          transition: 'color .3s ease-in-out',
        },
        '& button': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& input': {
          fontSize: 16,
          fontFamily: theme.fonts[0],
          padding: 0,
        },
        '& h1, & h2, & h3, & h4': {
          fontWeight: 'normal',
          margin: 0,
          padding: 0,
        },
        '& .canvas': {
          position: 'fixed!important',
          zIndex: -1,
          top: 0,
          left: 0,
          height: 'calc(var(--vh) + 115px)!important',
          width: '100vw!important',
        },
        '& table': {
          width: '100%',
          '& th, & td': {
            paddingRight: 8,
          },
        },
        /*------------------------------
        COOKIEBOT
        ------------------------------*/
        '& div#CybotCookiebotDialog': {
          width: 'auto!important',
          position: 'fixed',
          bottom: '28px',
          left: 'auto!important',
          top: 'auto!important',
          right: '5.5vw',
          background: 'rgba(0, 0, 0, .1)',
          backdropFilter: 'blur(10px)',
          boxShadow: 'inset 0 0 0 1px rgba(255, 255, 255, .1), 0 20px 20px -15px rgba(0, 0, 0, .1)',
          borderRadius: '30px',
          margin: '0',
          padding: '10px 4px 10px 21px',
        },
        '& div#CybotCookiebotDialogPoweredbyLink': {
          display: 'none',
        },

        '& #CybotCookiebotDialogBodyButtonDecline': {
          display: 'none!important',
        },

        '& h2#CybotCookiebotDialogBodyContentTitle': {
          color: '#fff!important',
          fontWeight: 'normal!important',
          fontSize: '14px!important',
          margin: '0!important',
          padding: '0!important',
        },

        '& div#CybotCookiebotDialogBodyContent': {
          margin: '0!important',
          minHeight: '0!important',
          padding: '0!important',
          flex: '1 0 auto!important',
        },

        '& div#CybotCookiebotDialogBody': {
          display: 'flex!important',
          alignItems: 'center!important',
        },

        '& div#CybotCookiebotDialogBodyButtons': {
          padding: '0',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          margin: '0',
        },
        '& a#CybotCookiebotDialogBodyButtonAccept': {
          borderRadius: '20px',
          padding: '10px 25px',
          margin: '0',
          background: '#f5a84e',
          border: 'none',
          position: 'relative',
          zIndex: '1',
          textDecoration: 'none',
          width: 'auto!important',
          minWidth: '0px!important',
        },
        '& a#CybotCookiebotDialogBodyButtonAccept:before': {
          content: '""',
          position: 'absolute',
          zIndex: '1',
          bottom: '10px',
          left: '25px',
          width: 'calc(100% - 50px)',
          height: '1px',
          background: '#fff',
          transform: 'scaleX(0)',
          transformOrigin: '100% 0',
          transition: 'transform .3s ease-out',
        },
        '& a#CybotCookiebotDialogBodyButtonAccept:hover:before': {
          transformOrigin: '0 0',
          transform: 'scaleX(1)',
        },
        '& #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink': {
          padding: '0',
          height: 'auto',
          width: 'auto',
          fontSize: '14px',
          lineHeight: '1.2',
          margin: '0 5px',
          color: 'rgba(255, 255, 255, .8)',
          textDecoration: 'none',
          display: 'inline-block',
          position: 'relative',
          zIndex: '1',
          transition: 'all .3s ease-out',
          background: 'none!important',
        },
        '& #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink:hover': {
          textDecoration: 'none',
          color: '#fff',
        },
        '& #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink:before': {
          content: '""',
          position: 'absolute',
          zIndex: '1',
          bottom: '0',
          left: '0',
          width: '100%',
          height: '1px',
          background: '#fff',
          transform: 'scaleX(0)',
          transformOrigin: '100% 0',
          transition: 'transform .3s ease-out',
        },
        '& #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink:hover:before': {
          transformOrigin: '0 0',
          transform: 'scaleX(1)',
        },
        '& a#CybotCookiebotDialogBodyButtonDetails': {
          background: 'none',
          border: 'none',
        },
        '& div#CybotCookiebotDialogDetail': {
          background: 'none',
          marginTop: '7px',
          borderTop: '1px solid rgba(255, 255, 255, .2)',
        },
        '& div#CybotCookiebotDialogDetailFooter': {
          background: 'none',
          color: '#fff',
        },
        '& #CybotCookiebotDialogDetailFooter a': {
          color: '#fff!important',
        },
        '& a#CybotCookiebotDialogDetailBodyContentTabsOverview': {
          background: 'none',
          border: 'none',
          color: '#fff!important',
        },
        '& div#CybotCookiebotDialogDetailBodyContentTextAbout': {
          background: 'none',
        },
        '& div#CybotCookiebotDialogDetailBodyContent': {
          background: 'none',
          color: '#FFF',
          border: 'none',
          marginBottom: '0px',
          marginTop: '4px',
        },
        '& .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead td, .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead th': {
          background: 'none!important',
          color: '#fff!important',
          borderBottom: '1px solid rgba(255, 255, 255, .2)!important',
        },
        '& .CybotCookiebotDialogDetailBodyContentTabsItem': {
          background: 'none',
          color: '#fff!important',
          border: 'none',
        },
        '& .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td': {
          color: 'rgba(255, 255, 255, 1)!important',
          borderBottom: '1px solid rgba(255, 255, 255, .2)!important',
          borderRight: '1px solid rgba(255, 255, 255, .2)!important',
        },
        '& .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td a': {
          color: '#fff!important',
        },
        '& div#CybotCookiebotDialogDetailBodyContentCookieContainerTypes': {
          background: 'none!important',
        },
        '& .CybotCookiebotDialogDetailBodyContentCookieContainerTypes, .CybotCookiebotDialogDetailBodyContentIABv2Tab': {
          background: 'none',
          color: '#fff!important',
        },
        '& #CybotCookiebotDialogDetailBodyContent a': {
          background: 'none!important',
          color: '#fff!important',
          border: 'none!important',
          borderRight: '1px solid rgba(255, 255, 255, .2)!important',
        },
        '& #CybotCookiebotDialogDetailBodyContentTabs a': {
          background: 'none!important',
          color: '#fff!important',
          border: 'none!important',
          paddingLeft: '0px!important',
        },
        '& #CookiebotWidget': {
          display: 'none!important',
          pointerEvents: 'none!important',
          visibility: 'hidden!important',
        },
      },
      '::selection': {
        background: theme.colors[3],
      },
      '::-moz-selection': {
        background: theme.colors[10],
        color: theme.colors[0],
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    '@global': {
      body: {
        '--fontSize': '18px',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    '@global': {
      body: {
        /*------------------------------
        COOKIEBOT
        ------------------------------*/
        '& div#CybotCookiebotDialog': {
          bottom: '20px!important',
          left: '20px!important',
          top: 'auto!important',
          right: '20px!important',
          padding: '18px 2px 8px 20px!important',
          borderRadius: '27px!important',
        },
        '& div#CybotCookiebotDialogBody': {
          flexWrap: 'wrap!important',
        },
        '& h2#CybotCookiebotDialogBodyContentTitle': {
          fontSize: '12px!important',
        },
        '& div#CybotCookiebotDialogBodyButtons': {
          flex: '1 1 100%',
          justifyContent: 'space-between',
        },
        '& #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink': {
          whiteSpace: 'nowrap',
          fontSize: '12px!important',
          margin: '0px!important',
        },
        '& a#CybotCookiebotDialogBodyButtonAccept': {
          whiteSpace: 'nowrap',
          fontSize: '12px!important',
          padding: '10px 18px!important',
        },
      },
    },
  }

  return output
}

export default global
