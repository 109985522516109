const style = (theme) => {
  const output = {
    root: {
    },
    header: {
      extend: theme.wrapper,
      marginBottom: 30,
      display: 'flex',
    },
    counter: {
      fontSize: 20,
      color: theme.colors[1],
    },
    carousel: {
      '& .slick-list': {
        overflow: 'hidden',
      },
      '& .slick-dots': {
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        bottom: -40,
        '& > *:not(:last-child)': {
          marginRight: 8,
        },
        '& li': {
          width: 11,
          height: 11,
          display: 'inline-block',
          '& button': {
            cursor: 'pointer',
            fontSize: 0,
            '&::before': {
              content: '""',
              display: 'block',
              width: 11,
              height: 11,
              backgroundColor: 'transparent',
              border: `1px solid ${theme.colors[3]}`,
              transition: `all 0.3s ${theme.easings['power4.out']}`,
              borderRadius: '50%',
              opacity: 1,
            },
          },
          '&.slick-active': {
            '& button': {
              '&::before': {
                backgroundColor: theme.colors[3],
              },
            },
          },
        },
      },
      '& .slick-arrow': {
        width: 48,
        height: 48,
        backgroundColor: theme.colors[1],
        borderRadius: '50%',
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        '& svg': {
          width: 8,
          height: 15,
          fill: theme.colors[3],
        },
      },
      '& .slick-prev': {
        left: 0,
      },
      '& .slick-next': {
        transform: 'translateY(-50%) rotate(180deg)',
        right: 0,
      },
      '& .slick-track': {
        display: 'flex',
      },
      '& .slick-active': {
        '& $carouselImg': {
          '& img': {
            opacity: 1,
            transform: 'none',
          },
        },
      },
    },
    slick: {
      position: 'relative',
    },
    carouselItem: {
      cursor: 'pointer',
      padding: 0,
      height: '100%',
      display: 'flex!important',
      alignItems: 'center',
    },
    carouselImg: {
      overflow: 'hidden',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      '& img': {
        transition: 'all .6s ease-in-out',
        opacity: 0.2,
        transform: 'scale(1.2)',
        height: 'auto',
        verticalAlign: 'middle',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    carouselItem: {
      cursor: 'pointer',
      padding: 0,
      height: '100%',
      display: 'flex!important',
      alignItems: 'center',
    },
  }

  return output
}

export default style
