import { forwardRef } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import DelayLink from '@/components/DelayLink'
import style from './style'

const useStyles = createUseStyles(style)

const BtnLink = forwardRef(({
  className,
  label,
  link,
  color,
}, ref) => {
  const classes = useStyles({ color })

  return (
    <DelayLink
      to={link}
      ref={ref}
      className={classNames({
        [classes.root]: true,
        className,
      })}
    >
      <div>{label}</div>
      <svg><use xlinkHref="#ico-arrow" /></svg>
    </DelayLink>
  )
})

export default BtnLink
