import { useEffect, useContext, useRef, useLayoutEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import ally from 'ally.js'
import HTMLParse from 'react-html-parser'
import { Context } from '@/context'
import NavLink from '@/components/NavLink'
import usePrevious from '@/hooks/usePrevious'
import * as layerActions from '@/actions/layer'
import style from './style'
import { theme } from '../../style'

const useStyles = createUseStyles(style)

const transform = (node, i) => { // eslint-disable-line
  if (node.type === 'tag' && node.name === 'a' && node.children[0].data.startsWith('ico')) {
    return (
      <a
        href={node.attribs.href}
        key={i.toString()}
      >
        <svg className={node.children[0].data}>
          <use xlinkHref={`#${node.children[0].data}`} />
        </svg>
      </a>
    )
  }
}

const Nav = ({
  mainNav,
  extraNav,
}) => {
  const { headerHeight, headerRef, cookieRef } = useContext(Context)
  const classes = useStyles({ headerHeight })
  const $containerNav = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isMenuOpen, strings } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    strings: state.options.strings,
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  const $root = useRef()
  const disableAllTabIndex = useRef(null)

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (mainNav?.length && extraNav?.length) setReady(true)
  }, [mainNav, extraNav])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = () => {
    gsap.set($root.current, { autoAlpha: 0, x: 100 })
    gsap.set($containerNav.current, { autoAlpha: 0, x: 50 })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isMenuOpen) closeMenu()
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    if (theme.detect.hasTouch) {
      document.body.style.overflowY = isMenuOpen ? 'hidden' : 'visible'
    }
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isMenuOpen])

  const openMainMenu = () => {
    gsap.killTweensOf([$root.current, $containerNav.current])
    const tl = gsap.timeline({ ease: 'power3.inOut' })
    tl
      .to($root.current, {
        duration: 0.7,
        x: 0,
        autoAlpha: 1,
      })
      .to([$containerNav.current], {
        duration: 0.7,
        autoAlpha: 1,
        x: 0,
        stagger: 0.08,
      }, '<0.2')
  }

  const closeMainMenu = () => {
    gsap.killTweensOf([$root.current, $containerNav.current])
    gsap.to($root.current, {
      duration: 0.7,
      autoAlpha: 0,
      x: 100,
    })
    gsap.to($containerNav.current, {
      duration: 0.7,
      autoAlpha: 0,
      x: 50,
    })
  }

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (ready) init()
  }, [ready])

  /*------------------------------
  Manage Accessibility
  ------------------------------*/
  useEffect(() => {
    if (isMenuOpen) {
      disableAllTabIndex.current = ally.maintain.disabled({
        filter: [$root.current, headerRef.current, ...cookieRef.current ? [cookieRef.current] : []],
      })
    }
    if (!isMenuOpen && disableAllTabIndex.current !== null) disableAllTabIndex.current.disengage()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isMenuOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isMenuOpen) openMainMenu()
    if (prevIsOpen !== undefined && !isMenuOpen) closeMainMenu()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if ((prevPathname !== pathname) && isMenuOpen) {
      setTimeout(() => {
        closeMenu()
      }, 500)
    }
  }, [pathname, isMenuOpen])

  const renderNav = (nav, type) => {
    return (
      <nav
        className={classNames({
          [classes.nav]: true,
          [classes.mainNav]: type === 'main',
          [classes.extraNav]: type === 'extra',
        })}
      >
        <ul>
          {nav.map((item) => {
            return (
              <li key={item.ID}>
                <NavLink item={item} />
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }

  return ready && (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <div
        className={classes.container}
        ref={$containerNav}
      >
        <div data-main-nav>
          {renderNav(mainNav, 'main')}
        </div>
        <div data-extra-nav>
          {renderNav(extraNav, 'extra')}
        </div>
        <div data-footer-nav>
          <div className={classes.footerNav}>
            {HTMLParse(strings.menu_footer, { transform })}
          </div>
        </div>
      </div>
    </div>
  )
}

Nav.propTypes = {
  mainNav: PropTypes.array,
}

Nav.defaultProps = {
  mainNav: [],
}

export default Nav
