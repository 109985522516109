/* Polyfill */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'

/* Accessibility */
import 'focus-visible'

import { createRoot } from 'react-dom/client'
import { JssProvider } from 'react-jss'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import createStore, { history } from '@/base/store'
import '@/base/axios'
import tracking from '@/tracking'
import registerServiceWorker from '@/pwa/serviceWorker'
import AppComponent from './app'

tracking()
objectFitImages()

const store = createStore()

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <JssProvider id={{ minify: process.env.NODE_ENV === 'production' }}>
          <AppComponent />
        </JssProvider>
      </ConnectedRouter>
    </Provider>
  )
}

const root = createRoot(document.getElementById('root'))

root.render(<App />)

/* Register Service Worker */
registerServiceWorker()
