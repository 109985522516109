const style = (theme) => {
  const output = {
    root: {
      width: 40,
      height: 40,
    },
    svg: {
      width: 40,
      height: 40,
    },
    path: {
      stroke: (props) => (props.headerInverse ? theme.colors[1] : theme.colors[0]),
      fill: 'none',
      strokeWidth: 2,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      transition: 'stroke .3s',
    },
    isActive: {
      '& $path': {
        stroke: theme.colors[0],
      },
    },
  }

  return output
}

export default style
