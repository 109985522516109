const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[1],
      fontSize: 18,
      paddingBottom: 10,
      display: 'inline-block',
      paddingRight: 40,
      overflow: 'hidden',
      position: 'relative',
      zIndex: 1,
      '--color': (props) => props.color || theme.colors[1],
      '& *': {
        cursor: 'pointer',
      },
      '& svg': {
        position: 'absolute',
        right: 0,
        top: 6,
        width: 8,
        height: 10,
        transition: 'transform .5s ease-in-out',
        fill: 'var(--color)',
        transform: 'scale(1)',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        left: 0,
        width: '300%',
        height: 1,
        background: 'linear-gradient(to left, var(--color) 33.3%, transparent 33.3%, transparent 66.6%, var(--color) 66.6%)',
        transition: 'transform .7s ease-in-out',
        transform: 'translateX(-66.6%)',
      },
      '&:hover': {
        '&:before': {
          transform: 'translateX(0%)',
        },
        '& svg': {
          transform: 'scale(1.2) translateY(0px)',
        },
      },
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {
    root: {
      fontSize: 16,
      paddingBottom: 6,
      paddingRight: 30,
      '& svg': {
        top: 5,
        width: 7,
        height: 9,
      },
    },
  }

  return output
}

export default style
