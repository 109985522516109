import { useEffect, useRef, useState } from 'react'
import FontFaceObserver from 'fontfaceobserver'

const usePreloadFont = ({
  cb,
}) => {
  const [isFontsReady, setFontsReady] = useState(false)
  const fontA = useRef(new FontFaceObserver('Albra-grotesk-regular'))
  const fontB = useRef(new FontFaceObserver('Albra-sans-light'))

  useEffect(() => {
    Promise.all([fontA.current.load(), fontB.current.load()]).then(() => {
      setFontsReady(true)
    })
  }, [])

  useEffect(() => {
    if (isFontsReady) cb()
  }, [isFontsReady])
}

export default usePreloadFont
