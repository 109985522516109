import { useEffect, useRef, useContext } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import { Context } from '@/context'
import style from './style'

const useStyles = createUseStyles(style)

const Burger = ({ isActive, isHover }) => {
  const { headerInverse } = useContext(Context)
  const classes = useStyles({ headerInverse })
  const $path1 = useRef()
  const $path2 = useRef()
  const $path3 = useRef()

  gsap.defaults({
    duration: 0.5,
  })

  useEffect(() => {
    gsap.set([$path1.current, $path2.current, $path3.current], {
      strokeDasharray: '12px 42px',
      strokeDashoffset: '0px',
    })
  }, [])

  useEffect(() => {
    if (!isActive) {
      gsap.to($path1.current, {
        strokeDashoffset: isHover ? '6px' : '0px',
      })
      gsap.to($path2.current, {
        strokeDashoffset: isHover ? '3px' : '0px',
      })
      gsap.to($path3.current, {
        strokeDashoffset: '0px',
      })
    } else {
      gsap.to([$path1.current, $path2.current], {
        strokeDashoffset: '-29px',
      })
      gsap.to($path3.current, {
        strokeDashoffset: '12px',
      })
    }
  }, [isHover, isActive])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.isActive]: isActive,
        [classes.isHover]: isHover,
      })}
    >
      <svg className={classes.svg} viewBox="0 0 40 40">
        <path
          ref={$path1}
          className={classes.path}
          d="M14,15h12h1l0.6-0.6c0.2-0.2,0.2-0.7,0-0.9v0c-0.4-0.4-0.8-0.6-1.3-0.6h-9c-1.4,0-2.3,1.6-1.5,2.8l0,0l8.5,8.5"
        />
        <path
          ref={$path2}
          className={classes.path}
          d="M14,25h12h1l0.6,0.6c0.2,0.2,0.2,0.7,0,0.9l0,0c-0.4,0.4-0.8,0.6-1.3,0.6h-9c-1.4,0-2.3-1.6-1.5-2.8l0,0 l8.5-8.5"
        />
        <line
          ref={$path3}
          className={classes.path}
          x1="14"
          y1="20"
          x2="26"
          y2="20"
        />
      </svg>
    </div>
  )
}

export default Burger
