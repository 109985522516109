import PropTypes from 'prop-types'

const CarouselPagination = ({
  items,
  active,
}) => {
  return (
    <div className="CarouselPagination__root">
      <span className="CarouselPagination__current">
        {active < 9 ? `0${active + 1}` : (active + 1)}
      </span>
      {'/'}
      {items.length - 1 < 9 ? `0${items.length}` : items.length}
    </div>
  )
}

CarouselPagination.propTypes = {
  items: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
}

export default CarouselPagination
