import { useEffect, useRef, useLayoutEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import usePrevious from '@/hooks/usePrevious'
import Carousel from '@/components/Carousel'
import * as layerActions from '@/actions/layer'
import style from './style'

const useStyles = createUseStyles(style)

const SideRight = () => {
  const [currentPage, setCurrentPage] = useState({})
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isSideRightOpen, childPages, currentID } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    isSideRightOpen: state.layer.layers.some((layer) => layer.id === 'side-right' && layer.isOpen),
    currentID: state.layer.layers.find((layer) => layer.id === 'side-right').dataID,
    childPages: state.content.pages.oppenheimer?.child || [],
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)
  const classes = useStyles({ isSideRightOpen })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: 'side-right' })), [dispatch])

  const $root = useRef()
  const $wrapper = useRef()

  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  useEffect(() => {
    setCurrentPage(childPages.find((p) => p.id === currentID))
  }, [currentID])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = () => {
    gsap.set($root.current, {
      autoAlpha: 0,
    })
    gsap.set($wrapper.current, {
      x: '30%',
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isSideRightOpen) closeLayer()
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isSideRightOpen])

  const openSideRight = () => {
    gsap.killTweensOf($root.current)
    gsap.to($root.current, {
      duration: 1,
      autoAlpha: 1,
      ease: 'power3.inOut',
    })
    gsap.to($wrapper.current, {
      duration: 1,
      x: '0%',
      ease: 'power3.inOut',
    })
  }

  const closeSideRight = () => {
    gsap.killTweensOf($root.current)
    gsap.to($root.current, {
      duration: 1,
      autoAlpha: 0,
      ease: 'power3.inOut',
    })
    gsap.to($wrapper.current, {
      duration: 1,
      x: '30%',
      ease: 'power3.inOut',
    })
  }

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (ready) init()
  }, [ready])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsSideRightOpen = usePrevious(isSideRightOpen)
  useEffect(() => {
    if (prevIsSideRightOpen !== undefined && isSideRightOpen) openSideRight()
    if (prevIsSideRightOpen !== undefined && !isSideRightOpen) closeSideRight()
  }, [isSideRightOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (prevPathname !== pathname) {
      setTimeout(() => {
        closeSideRight()
      }, 500)
    }
  }, [pathname])

  const handleClose = () => closeLayer()

  return ready && (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <button
        className={classes.close}
        onClick={handleClose}
        aria-label="close-modal"
      >
        <svg><use xlinkHref="#ico-close" /></svg>
      </button>
      <div className={classes.wrapper} ref={$wrapper}>
        <div className={classes.scrollable}>
          {currentPage && Object.keys(currentPage).length > 0 && (
            <>
              <div className={classes.pretitle}>{`Step ${(childPages.findIndex((p) => p.id === currentID)) + 1}`}</div>
              <h2
                className={classes.title}
                dangerouslySetInnerHTML={{ __html: currentPage.title.rendered }}
              />
              <p
                className={classes.text}
                dangerouslySetInnerHTML={{ __html: currentPage.content.rendered }}
              />
              {currentPage.acf.page_builder && currentPage.acf.page_builder.map((l, i) => {
                if (l.acf_fc_layout === 'text_section') {
                  return (
                    <p
                      key={i.toString()}
                      className={classes.text}
                      dangerouslySetInnerHTML={{ __html: l.text }}
                    />
                  )
                }
                if (l.acf_fc_layout === 'gallery_section') {
                  const gallery = l.gallery.length > 0 ? l.gallery.map((g) => ({
                    src: g.url,
                    width: g.width,
                    height: g.height,
                  })) : []
                  return l.gallery.length > 0 && (
                    <Carousel
                      key={i.toString()}
                      className={classes.gallery}
                      imagesList={gallery}
                      dots
                    />
                  )
                }
                if (l.acf_fc_layout === 'image_section') {
                  return (
                    <img
                      key={i.toString()}
                      className={classes.image}
                      src={l.image.url}
                      alt={l.image.description}
                    />
                  )
                }
                return false
              })}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SideRight
