const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
    },
    bar: {
      top: -2,
      left: 0,
      display: 'block',
      height: '100%',
      position: 'absolute',
      background: theme.colors[3],
      width: 4,
      transformOrigin: '0% 100%',
      transform: 'scaleY(0)',
      borderRadius: 2,
    },
    text: {
      display: 'inline-block',
      lineHeight: 1,
    },
    link: {
      position: 'absolute',
      zIndex: 2,
      height: 'calc(50% + 10px)',
      top: -10,
      left: -10,
      width: 'calc(100% + 40px)',
    },
    bottom: {
      top: 'auto',
      bottom: -10,
    },
  }
  return output
}

export default style
