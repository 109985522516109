import { createUseStyles } from 'react-jss'
import style from './style'

const useStyles = createUseStyles(style)

const StaticNoise = () => {
  const classes = useStyles()

  return (
    <div className={classes.noise} />
  )
}

export default StaticNoise
