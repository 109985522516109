import { useRef, useContext, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Logo from '@/components/Logo'
import Burger from '@/components/Burger'
import DelayLink from '@/components/DelayLink'
import BtnLink from '@/components/BtnLink'
import { Context } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf, useResize } from '@/components/Handlers'
import * as layerActions from '@/actions/layer'
import style from './style'

const useStyles = createUseStyles(style)

const Header = ({
  headerNav,
  treshold,
}) => {
  const { setHeaderRef, setHeaderHeight, headerInverse, isBackButtonVisible, backButtonLink } = useContext(Context)
  const theme = useTheme()
  const [isMainButtonHover, setMainButtonHover] = useState(false)
  const [isTop, setTop] = useState(true)
  const $root = useRef()
  const rootBounds = useRef()
  const history = useHistory()
  const { scroll, direction } = useContext(ScrollbarContext)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen, strings } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    strings: state.options.strings,
  }), shallowEqual)

  const classes = useStyles({ headerInverse, isMenuOpen })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const openMenu = useCallback(() => dispatch(layerActions.openMenu()), [dispatch])
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  const handleMouseMove = (e) => {
    if (e.type === 'mouseenter') setMainButtonHover(true)
    if (e.type === 'mouseleave') setMainButtonHover(false)
  }

  // share header ref to using it in <Nav/> for accessibility purpose
  useEffect(() => {
    setHeaderRef($root)
  }, [])

  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setHeaderHeight($root.current.getBoundingClientRect().height)
    }
  })

  const handleMainMenuClick = () => {
    if (isMenuOpen) closeMenu()
    if (!isMenuOpen) openMenu()
  }

  const preventPressSpacebar = useCallback((node) => {
    if (node !== null) {
      node.addEventListener('keyup', (e) => {
        if (e.keyCode === 32 && e.code === 'Space') e.preventDefault()
      })
    }
  }, [])

  const update = () => {
    if (scroll.current.y <= treshold && !isTop) setTop(true)
    if (scroll.current.y > treshold && isTop) setTop(false)
  }

  useRaf(() => {
    update()
  }, [isTop])

  const renderHeaderNav = useCallback(() => {
    return (
      <ul
        className={classNames({
          [classes.headerNav]: true,
          [classes.hide]: isMenuOpen,
        })}
      >
        {headerNav.map((item) => {
          return !item.classes.includes('btn') ? (
            <li key={item.ID}>
              <DelayLink
                to={item.url}
                className={classNames({
                  [classes.mainLink]: true,
                  [classes.btn]: item.classes.includes('btn'),
                })}
              >
                {item.title}
              </DelayLink>
            </li>
          ) : (
            <li key={item.ID}>
              <BtnLink
                link={item.url}
                label={item.title}
                theme="orange"
                fill
                slim
              />
            </li>
          )
        })}
      </ul>
    )
  }, [headerNav, isMenuOpen])

  const renderBurgerButton = () => {
    return (
      <button
        className={classNames({
          [classes.burgerButton]: true,
          isMenuOpen,
        })}
        onClick={handleMainMenuClick}
        aria-label="toggle-main-menu"
        ref={preventPressSpacebar}
        onMouseEnter={(e) => handleMouseMove(e)}
        onMouseLeave={(e) => handleMouseMove(e)}
      >
        <Burger
          isActive={isMenuOpen}
          isHover={isMainButtonHover}
          width={theme.header.burger.width}
          height={theme.header.burger.height}
        />
      </button>
    )
  }

  const renderBackButton = () => {
    return (
      <div
        className={classNames({
          [classes.backWrapper]: true,
          [classes.hide]: !isBackButtonVisible || isMenuOpen,
        })}
      >
        <button
          onClick={() => {
            if (backButtonLink !== '') history.push(backButtonLink)
            if (backButtonLink === '') history.goBack()
          }}
          className={classes.back}
        >
          {strings.back_label}
        </button>
      </div>
    )
  }

  return (
    <header
      className={classNames({
        [classes.root]: true,
        [classes.minimize]: direction === 'down' && !isTop && !theme.detect.isNativeScroll,
        [classes.background]: direction === 'up' && !isTop && !theme.detect.isNativeScroll,
      })}
      ref={$root}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.nav}>
            {renderHeaderNav()}
            {renderBackButton()}
            {renderBurgerButton()}
          </div>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  treshold: 20,
  menuLabel: 'Menu',
  headerNav: [],
}

export default Header
