const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '37vw',
      height: 'var(--vh)',
      background: theme.colors[11],
      color: theme.colors[1],
      borderTopLeftRadius: 22,
      borderBottomLeftRadius: 22,
      zIndex: theme.zindex.mainNav,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 60,
      paddingRight: 50,
      paddingTop: 80,
      paddingBottom: 22,
      overflowY: 'auto',
      minWidth: 470,
    },
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      transform: 'translateX(50px)',
    },
    nav: {
      fontSize: 40,
      '& a, & button': {
        position: 'relative',
        fontFamily: theme.fonts[1],
        color: theme.colors[1],
        lineHeight: 1.6,
        textAlign: 'left',
        whiteSpace: 'nowrap',
      },
    },
    mainNav: {
      marginBottom: 30,
    },
    extraNav: {
      marginBottom: 30,
      fontSize: 23,
      '& a, & button': {
        lineHeight: 1.5,
      },
    },
    footerNav: {
      fontSize: 14,
      color: theme.getRgba(theme.colors[1], 0.45),
      '& a': {
        color: theme.getRgba(theme.colors[1], 1),
      },
      '& svg': {
        width: 14,
        height: 14,
        fill: theme.colors[1],
        margin: [0, 2],
      },
    },
    active: {
      pointerEvents: 'none',
      cursor: 'default',
      paddingLeft: 30,
      '&::after': {
        transformOrigin: '0% 0%',
        transform: 'scaleY(1)!important',
      },
    },
    visible: {
      opacity: 1,
    },
  }

  /*------------------------------
  Max Height 680
  ------------------------------*/
  output['@media (max-height: 680px)'] = {
    nav: {
      fontSize: 32,
      '& a, & button': {
        lineHeight: 1.5,
      },
    },
    extraNav: {
      fontSize: 24,
      '& a, & button': {
        lineHeight: 1.6,
      },
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {
    root: {
      width: '50vw',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      width: '100vw',
      paddingTop: 100,
      paddingLeft: 30,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      minWidth: 0,
      paddingBottom: 90,
      height: '100vh',
    },

    footerNav: {
      marginRight: -40,
      fontSize: 14,
      '& a': {
        color: theme.getRgba(theme.colors[1], 1),
      },
    },
  }

  return output
}

export default style
