import { useEffect, useRef, useLayoutEffect, useState, useCallback, useContext } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import { Context as GeneralContext } from '@/context'
import usePrevious from '@/hooks/usePrevious'
import * as layerActions from '@/actions/layer'
import style from './style'

const useStyles = createUseStyles(style)

const BottomNavigation = () => {
  const { setCurrentPresentationIndex, currentPresentationIndex } = useContext(GeneralContext)
  const classes = useStyles()
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isBottomNavOpen, childPages } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    isBottomNavOpen: state.layer.layers.some((layer) => layer.id === 'bottom-nav' && layer.isOpen),
    childPages: state.content.pages.oppenheimer?.child || [],
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: 'bottom-nav' })), [dispatch])

  const $root = useRef()

  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = () => {
    gsap.set($root.current, {
      autoAlpha: 0,
      y: 50,
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isBottomNavOpen) closeLayer()
  }

  const prevCurrentPresentationIndex = usePrevious(currentPresentationIndex)
  useEffect(() => {
    if (prevCurrentPresentationIndex !== currentPresentationIndex) closeLayer()
  }, [currentPresentationIndex])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isBottomNavOpen])

  const openBottomNav = () => {
    gsap.killTweensOf($root.current)
    const tl = gsap.timeline({ ease: 'power3.inOut' })
    tl
      .to($root.current, {
        duration: 0.5,
        y: 0,
        autoAlpha: 1,
      })
  }

  const closeBottomNav = () => {
    gsap.killTweensOf($root.current)
    gsap.to($root.current, {
      duration: 0.5,
      autoAlpha: 0,
      y: 50,
      ease: 'power3.inOut',
    })
  }

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (ready) init()
  }, [ready])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsSideRightOpen = usePrevious(isBottomNavOpen)
  useEffect(() => {
    if (prevIsSideRightOpen !== undefined && isBottomNavOpen) openBottomNav()
    if (prevIsSideRightOpen !== undefined && !isBottomNavOpen) closeBottomNav()
  }, [isBottomNavOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (prevPathname !== pathname) {
      setTimeout(() => {
        closeBottomNav()
      }, 500)
    }
  }, [pathname])

  const handleClose = () => closeLayer()

  return ready && (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.isOpen]: isBottomNavOpen,
      })}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <button
        className={classes.close}
        onClick={handleClose}
        aria-label="close-modal"
      >
        <svg><use xlinkHref="#ico-close" /></svg>
      </button>
      <div className={classes.wrapper}>
        {childPages.length > 0 && (
          <ul className={classes.childPageNavigation}>
            {childPages.map((item, i) => (
              <li key={item.id}>
                <button // eslint-disable-line
                  dangerouslySetInnerHTML={{ __html: `<small>${i + 1}<svg viewBox="0 0 80 80"><path d="M75.8 40L57.9 9H22.1L4.2 40l17.9 31h35.8z" /></svg></small> ${item.title.rendered}` }}
                  onClick={() => setCurrentPresentationIndex(i + 1)}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default BottomNavigation
