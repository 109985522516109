import {
  RECEIVE_FORM,
} from '@/actions/types'

const initialState = {
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FORM:
      state = {
        ...state,
        [action.key]: action.payload,
      }
      break
    default:
      return state
  }
  return state
}
